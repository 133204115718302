import Form from "./pages/Form";

//
function App() {
  return (
    <div className="app">
      <Form />
    </div>
  );
}

export default App;
