import * as yup from "yup";

export const validationFormRiada = yup.object().shape({
  trainer: yup.object().shape({
    firstName: yup
      .string()
      .min(2, " يجب ان يحتوي علي حرفين او اكثر")
      .max(20, "الحد الاقصى للآسم ٢٠ حرف")
      .required(" الحقل مطلوب"),
    fatherName: yup
      .string()
      .min(2, " يجب ان يحتوي علي حرفين او اكثر")
      .max(20, "الحد الاقصى للآسم ٢٠ حرف")
      .required(" الحقل مطلوب"),
    grandFatherName: yup
      .string()
      .min(2, " يجب ان يحتوي علي حرفين او اكثر")
      .max(11, "الحد الاقصى للآسم ٢٠ حرف")
      .required(" الحقل مطلوب"),
    // email: yup
    //   .string()
    //   .email("عنوان البريد الالكتروني غير صالح")
    //   .trim()
    //   .optional(),
    phone: yup
      .string()
      .required("الحقل مطلوب")
      .min(10, "يرجى ادخال رقم صالح للاستخدام")
      .max(15, "يرجى ادخال رقم صالح للاستخدام"),
  }),
});

export const validationSubmit = ({
  ozara,
  needs,
  form,
  otherNeed,
  countTable,
  seatingbenches,
  centerName,
  checkHaveNeed,
  chooseCenter,
}) => {
  let error = "";
  if (form.values.trainer.birthdate === "") {
    return (error = "حقل سنة الميلاد مطلوب يرجى ادخال معلومات كاملة");
  }

  if (form.values.trainer.province === "") {
    return (error = "حقل المحافظة مطلوب يرجى ادخال معلومات كاملة");
  }

  if (form.values.trainer.degree === "") {
    return (error = "حقل التحصيل الدراسي مطلوب يرجى ادخال معلومات كاملة");
  }

  if (form.values.address.province === "") {
    return (error = "حقل المحافظة مطلوب يرجى ادخال معلومات كاملة");
  }

  if (ozara.mainWorking === "") {
    return (error = "حقل الوزارات مطلوب");
  }

  if (
    ozara.mainWorking !== "labor" &&
    ozara.name === "" &&
    chooseCenter === ""
  ) {
    return (error = "ادخالات خاطئة توجد حقول مطلوبة لقسم الوزارات");
  }

  if (
    ozara.mainWorking === "education" &&
    ozara.name === "" &&
    chooseCenter === ""
  ) {
    return (error = "يرجى ادخال الاعدادية المهنية");
  }

  if (ozara.subWorking === "mahaad" && ozara.subName === "") {
    return (error = "اختر الجامعة والمعهد التابع لها ، الحقل مطلوب");
  }

  if (checkHaveNeed === null) {
    return (error = "حقل هل لديك قاعة جاهزة مطلوب");
  }

  if (
    ozara.subWorking === "goveUniversty" ||
    ozara.subWorking === "privateUniversty" ||
    ozara.subWorking === "mahaad"
  ) {
    if (chooseCenter === "" && centerName === "")
      return (error = " المركز مطلوب");
  }

  if (chooseCenter === "" || centerName !== "") {
    if (
      ozara.mainWorking !== "education" &&
      ozara.mainWorking !== "youthAndSports" &&
      ozara.mainWorking !== "vocationalTraining" &&
      ozara.mainWorking !== "labor"
    ) {
      form.values.center.centerName = centerName;
    }
  }

  if (needs.table === true && countTable !== "") {
    needs.table = countTable;
  } else {
    needs.table = null;
  }

  if (needs.chir === true && seatingbenches !== "") {
    if (seatingbenches > 27) {
      return (error = "الحد الاقصى لمقاعد الجلوس 27 مقعد");
    }
    needs.chir = seatingbenches;
  } else {
    needs.chir = null;
  }

  if (needs.others === true && otherNeed.length < 5) {
    return (error = "يرجى ادخال احتياجك");
  }

  if (needs.others === true && otherNeed.length > 5) {
    needs.others = otherNeed;
  } else {
    needs.others = null;
  }

  if (!form.values.trainer.phone.startsWith("+964")) {
    form.values.trainer.phone = "+964" + form.values.trainer.phone;
  }

  if (form.values.trainer.email === "") {
    form.values.trainer.email = null;
  }

  form.values.center.mainType = ozara.mainWorking;

  if (
    ozara.mainWorking === "higherEducation" &&
    ozara.subWorking == "goveUniversty"
  ) {
    form.values.center.isMahaad = false;
    form.values.center.isAhlle = false;
    form.values.center.universtyName = ozara.name;
  }
  if (
    ozara.mainWorking === "higherEducation" &&
    ozara.subWorking == "privateUniversty"
  ) {
    form.values.center.isMahaad = false;
    form.values.center.isAhlle = true;
    form.values.center.universtyName = ozara.name;
  }
  if (ozara.mainWorking === "higherEducation" && ozara.subWorking == "mahaad") {
    form.values.center.isMahaad = true;
    form.values.center.isAhlle = false;
    form.values.center.universtyName = ozara.name;
    form.values.center.mahaadName = ozara.subName;
  }

  if (ozara.mainWorking === "education" && chooseCenter === "") {
    form.values.center.muderrea = ozara.subWorking;
    form.values.center.schoolName = ozara.name;
  }

  if (
    ozara.mainWorking === "youthAndSports" ||
    ozara.mainWorking === "vocationalTraining"
  ) {
    if (chooseCenter === "") {
      form.values.center.type = ozara.subWorking;
      form.values.center.publicName = ozara.name;
    }
  }

  if (
    needs.laptop == true ||
    needs.datashow == true ||
    needs.screen == true ||
    needs.smartboard == true ||
    needs.printer == true ||
    needs.table == true ||
    needs.chir == true ||
    needs.others == true
  ) {
    form.values.needs = needs;
  }
  return error;
};
