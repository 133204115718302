export const arrayOzara = [
  { label: "وزارة التعليم العالي والبحث العلمي", value: "higherEducation" },
  { label: "وزارة التربية / التعليم المهني", value: "education" },
  { label: "مديرية الشباب والرياضة", value: "youthAndSports" },
  { label: "دائرة العمل والتدريب المهني", value: "vocationalTraining" },
];

export const arrayOzaraIncubator = [
  { label: "مديرية الشباب والرياضة", value: "youthAndSports" },
  { label: "دائرة العمل والتدريب المهني", value: "vocationalTraining" },
  { label: "وزارة العمل والشؤون الاجتماعية", value: "labor" },
];

// new
export const muderreaMoh = [
  { label: "الكرخ الاولى", value: "الكرخ الاولى" },
  { label: "الكرخ الثانية", value: "الكرخ الثانية" },
  { label: "الكرخ الثالثة", value: "الكرخ الثالثة" },
  { label: "الرصافة الاولى", value: "الرصافة الاولى" },
  { label: "الرصافة الثانية", value: "الرصافة الثانية" },
  { label: "الرصافة الثالثة", value: "الرصافة الثالثة" },

  { label: "نينوى", value: "نينوى" },

  { label: "المثنى", value: "المثنى" },

  { label: "ميسان", value: "ميسان" },

  { label: "كركوك", value: "كركوك" },

  { label: "كربلاء", value: "كربلاء" },

  { label: "ديالى", value: "ديالى" },

  { label: "البصرة", value: "البصرة" },

  { label: "بابل", value: "بابل" },

  { label: "الانبار", value: "الانبار" },

  { label: "القادسية", value: "القادسية" },

  { label: "صلاح الدين", value: "صلاح الدين" },

  { label: "النجف", value: "النجف" },

  { label: "واسط", value: "واسط" },

  { label: "ذي قار", value: "ذي قار" },
];

export const namesMahed = [
  {
    label: "الجامعة التقنية الشمالية",
    value: { label: "الجامعة التقنية الشمالية", index: 0 },
  },
  {
    label: "جامعة الفرات الاوسط التقنية",
    value: { label: "جامعة الفرات الاوسط التقنية", index: 1 },
  },
  {
    label: "الجامعة التقنية الوسطى",
    value: { label: "الجامعة التقنية الوسطى", index: 2 },
  },
  {
    label: "الجامعة التقنية الجنوبية",
    value: { label: "الجامعة التقنية الجنوبية", index: 3 },
  },
];

// arrays for mahhad
export const maheds = [
  [
    "المعهد التقني الموصل التخصصات الادارية",
    "المعهد التقني نينوى التخصصات الادارية",
    "المعهد التقني كركوك التخصصات الادارية",
    "المعهد التقني الحويجة التخصصات الادارية",
    "المعهد التقني الدور(صلاح الدين )التخصصات الادارية",
    "المعهد التقني الموصل التخصصات الطبية",
    "المعهد التقني الموصل التخصصات الطبية قسم تقنيات التمريض (الاسعاف الفوري) للذكور",
    "المعهد التقني الموصل التخصصات الطبية قسم تقنيات التمريض (الاسعاف الفوري) للبنات",
    "المعهد التقني كركوك التخصصات الطبية",
    "المعهد التقني كركوك التخصصات الطبية قسم تقنيات التمريض للذكور",
    "المعهد التقني كركوك التخصصات الطبية قسم تقنيات التمريض للبنات",
    "المعهد التقني الدور(صلاح الدين )التخصصات الطبية",
    "المعهد التقني الحويجة التخصصات الطبية قسم تقنيات المختبرات الطبية",
    "المعهد التقني الموصل التخصصات الهندسية",
    "المعهد التقني كركوك التخصصات الهندسية",
    "المعهد التقني الحويجة التخصصات الهندسية",
    "المعهد التقني الدور(صلاح الدين )التخصصات الهندسية",
    "المعهد التقني الموصل التخصصات الزراعية",
    "المعهد التقني الحويجة التخصصات الزراعية قسم تقنيات الانتاج النباتي",
    "المعهد التقني الاداري/الحويجة قسم تقنيات ادارة المكتب",
    "المعهد التقني الاداري/الحويجة قسم تقنيات ادارة المواد",
    "المعهد التقني الاداري/الدور(صلاح الدين ) قسم تقنيات المحاسبة",
    "المعهد التقني الاداري/كركوك قسم تقنيات الادارة القانونية",
    "المعهد التقني الاداري/كركوك قسم تقنيات المحاسبة",
    "المعهد التقني الاداري/موصل قسم تقنيات ادارة المكتب",
    "المعهد التقني الاداري/موصل قسم تقنيات انظمة الحاسبات",
    "المعهد التقني التكنلوجي/الحويجة قسم التقنيات الكهربائية",
    "المعهد التقني التكنلوجي/الحويجة قسم التقنيات الميكانيكية",
    "المعهد التقني التكنلوجي/الدور(صلاح الدين ) قسم تقنيات الالكترونيك",
    "المعهد التقني التكنلوجي/الدور(صلاح الدين ) قسم تقنيات الميكانيك",
    "المعهد التقني التكنلوجي/الموصل قسم التقنيات الكهربائية",
    "المعهد التقني التكنلوجي/الموصل قسم التقنيات الميكانيكية",
    "المعهد التقني التكنلوجي/كركوك قسم التقنيات الالكترونية",
    "المعهد التقني التكنلوجي/كركوك قسم التقنيات الكهربائية",
    "المعهد التقني التكنلوجي/كركوك قسم تقنيات الميكانيك",
    "المعهد التقني التكنلوجي/كركوك ميكانيك القدرة",
    "المعهد التقني التكنلوجي/الموصل قسم التقنيات الالكترونية",
    "المعهد التقني الطبي/كركوك قسم تقنيات التمريض",
    "المعهد التقني الاداري الدور(صلاح الدين )قسم تقنيات المحاسبة",
    "المعهد التقني فنون كركوك قسم تقنيات صناعة الملابس",
  ],
  [
    "المعهد التقني بابل التخصصات الادارية",
    "المعهد التقني المسيب التخصصات الادارية",
    "المعهد التقني الكوفة التخصصات الادارية قسم تقنيات ادارة المواد",
    "المعهد التقني النجف التخصصات الادارية",
    "المعهد التقني كربلاء التخصصات الادارية",
    "المعهد التقني القادسية التخصصات الادارية",
    "المعهد التقني السماوة التخصصات الادارية",
    "المعهد التقني الرميثة التخصصات الادارية قسم تقنيات المحاسبة",
    "المعهد التقني فنون النجف",
    "المعهد التقني بابل التخصصات الطبية",
    "المعهد التقني بابل التخصصات الطبية قسم تقنيات التمريض للذكور",
    "المعهد التقني بابل التخصصات الطبية قسم تقنيات التمريض للبنات",
    "المعهد التقني الكوفة التخصصات الطبية",
    "المعهد التقني الكوفة التخصصات الطبية قسم تقنيات التمريض للذكور",
    "المعهد التقني الكوفة التخصصات الطبية قسم تقنيات التمريض للبنات",
    "المعهد التقني كربلاء التخصصات الطبية قسم تقنيات صحة المجتمع",
    "المعهد التقني القادسية التخصصات الطبية",
    "المعهد التقني القادسية التخصصات الطبية قسم تقنيات التمريض للذكور",
    "المعهد التقني القادسية التخصصات الطبية قسم تقنيات التمريض للبنات",
    "المعهد التقني السماوة التخصصات الطبية",
    "المعهد التقني السماوة التخصصات الطبية قسم تقنيات التمريض للذكور",
    "المعهد التقني السماوة التخصصات الطبية قسم تقنيات التمريض للبنات",
    "المعهد التقني المسيب التخصصات الطبية قسم تقنيات المختبرات الطبية",
    "المعهد التقني النجف التخصصات الطبية",
    "المعهد التقني بابل التخصصات الهندسية",
    "المعهد التقني المسيب التخصصات الهندسية",
    "المعهد التقني الكوفة التخصصات الهندسية",
    "المعهد التقني النجف التخصصات الهندسية",
    "المعهد التقني كربلاء التخصصات الهندسية",
    "المعهد التقني القادسية التخصصات الهندسية قسم تقنيات الميكانيك",
    "المعهد التقني السماوة التخصصات الهندسية",
    "المعهد التقني الرميثة التخصصات الهندسية قسم تقنيات الكهرباء",
    "المعهد التقني المسيب التخصصات الزراعية",
    "المعهد التقني الكوفة التخصصات الزراعية",
    "المعهد التقني الاداري/السماوة قسم تقنيات المحاسبة",
    "المعهد التقني الاداري/النجف قسم تقنيات السياحة وادارة الفنادق",
    "المعهد التقني الاداري/النجف قسم تقنيات المحاسبة",
    "المعهد التقني الاداري/رميثة قسم تقنيات المحاسبة",
    "المعهد التقني التكنلوجي/السماوة قسم تقنيات الكهرباء",
    "المعهد التقني التكنلوجي/السماوة قسم تقنيات المساحة",
    "المعهد التقني التكنلوجي/السماوة قسم تقنيات الميكانيك",
    "المعهد التقني التكنلوجي/النجف قسم تقنيات الكترونيك",
    "المعهد التقني الطبي/السماوة قسم تقنيات التمريض",
    "المعهد التقني الطبي/بابل قسم تقنيات التمريض",
    "المعهد التقني الطبي القادسية قسم تقنيات صحة المجتمع",
    "المعهد التقني الطبي السماوة قسم تقنيات صحة المجتمع",
    "المعهد التقني النجف تخصصات الفنون التطبيقية قسم تقنيات التصميم والتزيين المعماري",
    "المعهد التقني النجف تخصصات الفنون التطبيقية قسم تقنيات التصميم والتزيين المعماري",
    "المعهد التقني النجف تخصصات الفنون التطبيقية قسم تقنيات التصميم والتزيين المعماري",
    "المعهد التقني النجف تخصصات الفنون التطبيقية قسم تقنيات التصميم والتزيين المعماري",
  ],
  [
    "معهد الادارة الرصافة",
    "معهد الادارة التقني بغداد",
    "المعهد التقني الانبار التخصصات الادارية",
    "المعهد التقني بعقوبة التخصصات الادارية",
    "المعهد التقني الكوت التخصصات الادارية",
    "المعهد التقني الصويرة التخصصات الادارية",
    "المعهد التقني بلد التخصصات الادارية قسم تقنيات المحاسبة",
    "معهد الفنون التطبيقية بغداد",
    "المعهد الطبي بغداد باب المعظم",
    "المعهد الطبي بغداد باب المعظم قسم تقنيات التمريض للذكور",
    "المعهد الطبي بغداد باب المعظم قسم تقنيات التمريض للبنات",
    "المعهد التقني الطبي بغداد المنصور",
    "المعهد التقني الانبار التخصصات الطبية قسم تقنيات صحة المجتمع",
    "المعهد التقني بعقوبة التخصصات الطبية",
    "المعهد التقني بعقوبة التخصصات الطبية قسم تقنيات التمريض للذكور",
    "المعهد التقني بعقوبة التخصصات الطبية قسم تقنيات التمريض للبنات",
    "المعهد التقني الكوت التخصصات الطبية",
    "المعهد التقني الصويرة التخصصات الطبية قسم تقنيات المختبرات الطبية",
    "المعهد التقني الصويرة التخصصات الطبية قسم تقنيات التمريض للذكور",
    "المعهد التقني الصويرة التخصصات الطبية قسم تقنيات التمريض للبنات",
    "المعهد التقني بلد التخصصات الطبية قسم تقنيات المختبرات الطبية",
    "معهد التكنلوجيا بغداد",
    "المعهد التقني الانبار التخصصات الهندسية",
    "المعهد التقني بعقوبة التخصصات الهندسية",
    "المعهد التقني الكوت التخصصات الهندسية",
    "المعهد التقني الصويرة التخصصات الهندسية",
    "المعهد التقني الصويرة التخصصات الزراعية قسم تقنيات الانتاج النباتي",
    "معهد اعداد المدربين التقنيين بغداد",
    "المعهد التقني الاداري/الانبار قسم تقنيات الادارة القانونية",
    "المعهد التقني الاداري/الانبار قسم تقنيات المحاسبة",
    "المعهد التقني الاداري/الانبار قسم تقنيات انظمة الحاسبات",
    "المعهد التقني الاداري/الصويرة قسم تقنيات المحاسبة",
    "المعهد التقني الاداري/الصويرة قسم تقنيات أنظمة الحاسبات",
    "المعهد التقني الاداري/الكوت قسم تقنيات المحاسبة",
    "المعهد التقني الاداري/بعقوبة قسم تقنيات المحاسبة",
    "المعهد التقني التكنلوجي/الانبار قسم التقنيات الكهربائية",
    "المعهد التقني التكنلوجي/الصويرة قسم تقنيات الكهرباء",
    "المعهد التقني التكنلوجي/الكوت قسم تقنيات الكهرباء",
    "المعهد التقني التكنلوجي/الكوت قسم تقنيات المساحة",
    "المعهد التقني التكنلوجي/بعقوبة قسم تقنيات الميكانيك",
    "معهد الادارة التقني/بغداد قسم تقنيات الادارة القانونية",
    "معهد الادارة التقني/بغداد قسم تقنيات السياحة",
    "معهد الادارة التقني/بغداد قسم تقنيات المحاسبة",
    "معهد الادارة التقني/بغداد قسم تقنيات انظمة الحاسبات",
    "معهد الادارة/الرصافة قسم التقنيات المالية والمصرفية",
    "معهد الادارة/الرصافة قسم تقنيات ادارة المواد",
    "معهد الادارة/الرصافة قسم تقنيات المعلومات والمكتبات",
    "معهد الادارة/الرصافة قسم تقنيات انظمة الحاسوب",
    "معهد التكنلوجيا/بغداد قسم تقنيات الالكترونيك",
    "معهد التكنلوجيا/بغداد قسم تقنيات الكهرباء",
    "معهد الفنون التطبيقية/بغداد قسم تقنيات التصميم الطباعي",
    "معهد الفنون التطبيقية/بغداد قسم تقنيات التصميم والتزيين المعماري",
    "معهد الفنون التطبيقية/بغداد قسم تقنيات الخزف",
    "معهد الفنون التطبيقية/بغداد قسم تقنيات الخط والزخرفة",
    "معهد الفنون التطبيقية/بغداد قسم تقنيات تصميم الاقمشة",
    "معهد الفنون التطبيقية/بغداد قسم تقنيات صناعة الملابس",
    "معهد الفنون التطبيقية/بغداد قسم تقنيات فن الحلي والمجوهرات",
    "معهد الادارة/الرصافة قسم تقنيات المحاسبة",
    "معهد التكنلوجيا/بغداد قسم تقنيات الميكانيك",
    "معهد الادارة/الرصافة قسم تقنيات ادارة المكتب",
    "المعهد التقني التكنلوجي/بعقوبة قسم تقنيات الكهرباء",
    "المعهد التقني الطبي/الصويرة قسم تقنيات التمريض",
    "المعهد التقني الطبي/بعقوبة قسم تقنيات التمريض",
    "المعهد الطبي/بغداد باب المعظم قسم تقنيات التمريض",
  ],
  [
    "المعهد التقني البصرة التخصصات الادارية",
    "المعهد التقني العمارة التخصصات الادارية",
    "المعهد التقني الناصرية التخصصات الادارية",
    "المعهد التقني الشطرة التخصصات الادارية قسم تقنيات ادارة المواد",
    "المعهد التقني الناصرية تخصصات الفنون التطبيقية قسم تقنيات الفنون التطبيقية",
    "المعهد التقني البصرة التخصصات الطبية",
    "المعهد التقني البصرة التخصصات الطبية قسم تقنيات التمريض للذكور",
    "المعهد التقني البصرة التخصصات الطبية قسم تقنيات التمريض للبنات",
    "المعهد التقني العمارة التخصصات الطبية قسم تقنيات المختبرات الطبية",
    "المعهد التقني العمارة التخصصات الطبية قسم تقنيات التمريض للذكور",
    "المعهد التقني العمارة التخصصات الطبية قسم تقنيات التمريض للبنات",
    "المعهد التقني الناصرية التخصصات الطبية",
    "المعهد التقني الناصرية التخصصات الطبية قسم تقنيات التمريض للذكور",
    "المعهد التقني الناصرية التخصصات الطبية قسم تقنيات التمريض للبنات",
    "المعهد التقني البصرة التخصصات الهندسية",
    "المعهد التقني القرنة التخصصات الهندسية قسم التقنيات الكهربائية",
    "المعهد التقني العمارة التخصصات الهندسية",
    "المعهد التقني الناصرية التخصصات الهندسية",
    "المعهد التقني الشطرة التخصصات الهندسية",
    "المعهد التقني الشطرة التخصصات الزراعية",
    "المعهد التقني القرنة التخصصات الادارية قسم تقنيات انظمة الحاسبات",
    "المعهد التقني الاداري/البصرة قسم تقنيات ادارة المكتب",
    "المعهد التقني الاداري/البصرة قسم تقنيات المحاسبة",
    "المعهد التقني الاداري/العمارة قسم تقنيات المحاسبة",
    "المعهد التقني التكنلوجي/البصرة قسم التقنيات الالكترونية",
    "المعهد التقني التكنلوجي/البصرة قسم التقنيات الكهربائية",
    "المعهد التقني التكنلوجي/البصرة قسم التقنيات الميكانيكية",
    "المعهد التقني التكنلوجي/العمارة قسم تقنيات الكهرباء",
    "المعهد التقني التكنلوجي/البصرة قسم تقنيات الصناعات الكيمياوية",
    "المعهد التقني الطبي/البصرة قسم تقنيات التمريض",
    "المعهد التقني الطبي/العمارة قسم تقنيات التمريض",
    "المعهد التقني الطبي/الناصرية قسم تقنيات التمريض",
  ],
];

// array unversite
export const govUni = {
  بغداد: [
    "جامعة بغداد",
    "الجامعة المستنصرية",
    "الجامعة التكنولوجية",
    "الجامعة العراقية",
    "جامعة النهرين",
    "جامعة تكنولوجيا المعلومات والاتصالات",
    "جامعة الكرخ للعلوم",
    "جامعة ابن سينا للعلوم الطبية والصيدلانية",
    "الجامعة التقنية الوسطى",
  ],
  البصرة: [
    "جامعة البصرة",
    "جامعة البصرة للنفط والغاز",
    "الجامعة التقنية الجنوبية",
  ],
  نينوى: [
    "جامعة الموصل",
    "جامعة نينوى",
    "جامعة الحمدانية",
    "جامعة تلعفر",
    "الجامعة التقنية الشمالية",
  ],
  النجف: [
    "جامعة الكوفة",
    "جامعة جابر بن حيان الطبية",
    "جامعة الفرات الاوسط التقنية ",
    "كلية الامام الكاظم الجامعة",
  ],
  "صلاح الدين": ["جامعة تكريت", "جامعة سامراء"],
  القادسية: ["جامعة القادسية"],
  الانبار: ["جامعة الانبار", "جامعة الفلوجة"],
  بابل: ["جامعة بابل", "جامعة القاسم الخضراء"],
  ديالى: ["جامعة ديالى"],
  كربلاء: ["جامعة كربلاء"],
  "ذي قار": ["جامعة ذي قار", "جامعة سومر"],
  كركوك: ["جامعة كركوك"],
  واسط: ["جامعة واسط"],
  ميسان: ["جامعة ميسان"],
  المثنى: ["جامعة المثنى"],
};

export const priUni = {
  بغداد: [
    "كلية المنصور",
    "كلية التراث",
    "كلية الرافدين",
    "كلية المأمون",
    "كلية بغداد للعلوم الاقتصادية الجامعة",
    "كلية بغداد للعلوم الطبية",
    "كلية دجلة",
    "كلية السلام",
    "كلية مدينة العلم",
    "جامعة الامام جعفر الصادق",
    "كلية الرشيد",
    "كلية صدر العراق",
    "كلية الحكمة",
    "كلية اصول الدين",
    "كلية الاسراء",
    "كلية المصطفى",
    "كلية الفراهيدي",
    "كلية الفارابي",
    "كلية الباني",
    "كلية النخبة",
    "كلية النسور",
    "كلية الامال",
    "جامعة اوروك",
    "كلية الهادي",
    "جامعة البيان",
    "جامعة المصطفى الامين",
    "كلية آشور",
    "كلية الشرق الاوسط",
    " جامعة كلكامش",
    "جامعة المشرق",
    "كلية ابن خلدون",
    "الجامعة الامريكية",
    "جامعة الشعب",
  ], //baghdad 33 uni
  البصرة: [
    "كلية شط العرب",
    "كلية العراق",
    "كلية الكنوز",
    "كلية البصرة للعلوم والتكنولوجيا",
    "جامعة المعقل",
  ],
  نينوى: ["كلية الحدباء", "كلية النور"],
  النجف: [
    "الجامعة الاسلامية",
    "كلية الطوسي الجامعة",
    "جامعة الكفيل",
    "كلية الفقه الجامعة",
    "جامعة الامام جعفر الصادق",
    "معهد العلمين للدراسات العليا",
    //معهد المعلمين للدراسات العليا
  ],
  "صلاح الدين": ["جامعة الامام جعفر الصادق", "كلية الامام"],
  القادسية: ["الجامعة الاسلامية"],
  الانبار: [
    "كلية المعارف",
    "كلية جنات العراق للعلوم الانسانية",
    "كلية الهدى الجامعة",
  ],
  بابل: ["كلية المستقبل", "كلية الحلة", "الجامعة الاسلامية"],
  ديالى: ["كلية اليرموك", "كلية بلاد الرافدين", "جامعة الامام جعفر الصادق"],
  كربلاء: [
    "جامعة اهل البيت",
    "كلية الحسين الهندسية",
    "كلية الصفوة",
    "كلية الطف",
    "كلية الزهراوي",
    "كلية ابن حيان",
    "جامعة وارث الانبياء",
    "جامعة العميد",
    "جامعة الزهراء للبنات",
    "كلية الامل للعلوم الطبية التخصصية",
    "كلية الحسين (ع) الجامعة",
    "جامعة طهران للعلوم الطبية",
  ],
  "ذي قار": [
    "جامعة الامام جعفر الصادق",
    "كلية المزايا",
    "الجامعة الوطنية للعلوم والتكنولوجيا",
    "جامعة العين",
  ],
  كركوك: ["كلية القلم", "جامعة الكتاب", "جامعة الامام جعفر الصادق"],
  واسط: ["كلية الكوت"],
  ميسان: [
    "جامعة الامام جعفر الصادق",
    "كلية المنارة للعلوم الطبية",
    "كلية العمارة",
  ],
  المثنى: ["جامعة الامام جعفر الصادق"],
};
export const arrayMohs = Object.keys(govUni).map((moh) => {
  return { label: moh, value: moh };
});
