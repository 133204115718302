import { useForm, yupResolver } from "@mantine/form";
import { TextInput, Paper, Button, Select, Checkbox } from "@mantine/core";
import React, { useEffect, useState } from "react";
import { validationFormRiada, validationSubmit } from "../validation.js";
import logo from "../image/logo.png";
import group from "../image/Group.png";
import axios from "axios";

import {
  priUni,
  govUni,
  maheds,
  namesMahed,
  arrayMohs,
  muderreaMoh,
  arrayOzara,
} from "../data/arrays";

export default function FormRiada() {
  // state
  const [indexMahed, setIndexMahed] = useState(null);
  const [countTable, setCountTable] = useState("");
  const [otherNeed, setOtherNeed] = useState("");
  const [seatingbenches, setSeatingBenches] = useState("");
  const [arrayYears, setArrayYears] = useState([]);
  const [centerName, setCenterName] = useState("");
  const [stateProvince, setStateProvince] = useState("");
  const [dateUnversity, setDateUnversity] = useState([]);
  const [checkHaveNeed, setCheckHaveNeed] = useState(null);
  const [checkedNotFoundCenter, setCheckedNotFoundCenter] = useState(false);
  const [chooseCenter, setChooseCenter] = useState("");
  const [arrayCenters, serArrayCenters] = useState([]);
  const [loading, setLoading] = useState(false);

  //
  const [getIncubators, setGetIncubators] = useState([]);
  const [showCenters, setShowCenters] = useState(null);
  const [showIncubators, setShowIncubators] = useState(null);

  const [chooseIncubator, setChooseIncubator] = useState("");
  const url = "https://api.riyada.iq/api/";
  const urlClone = "https://phpstack-912641-3237802.cloudwaysapps.com/api/";
  // create axios
  const instance = axios.create({
    baseURL: url,
    validateStatus: function (status) {
      return (
        (status >= 200 && status < 300) ||
        status === 400 ||
        status === 404 ||
        status === 401 ||
        status === 500
      );
    },
  });

  //
  const [ozara, setOzara] = useState({
    mainWorking: "",
    subWorking: "",
    name: "",
    subName: "",
  });

  const [needs, setNeeds] = useState({
    laptop: false,
    datashow: false,
    screen: false,
    smartboard: false,
    printer: false,
    table: null,
    chir: null,
    others: null,
  });

  const [arrayMohsIncubator, setArrayMohsIncubator] = useState([]);
  const [chooseMohIncubator, setChooseMohIncubator] = useState("");

  const form = useForm({
    validate: yupResolver(validationFormRiada),
    initialValues: {
      trainer: {
        firstName: "",
        fatherName: "",
        grandFatherName: "",
        birthdate: "",
        province: "",
        degree: "",
        email: "",
        phone: "",
      },
      center: {},
      address: {
        province: "",
        city: "",
        alhayee: "",
        mahlla: "",
        zukak: "",
        endpoint: "",
      },
      needs: null,
    },
  });

  useEffect(() => {
    let newArr = [];
    for (let i = 1960; i <= 2023; i++) {
      newArr.push({ label: i, value: i });
      setArrayYears(newArr);
    }
  }, []);

  useEffect(() => {
    if (ozara.subWorking == "goveUniversty") {
      if (stateProvince !== "") {
        return setDateUnversity(govUni[stateProvince]);
      }
    }
    if (ozara.subWorking == "privateUniversty") {
      if (stateProvince !== "") {
        return setDateUnversity(priUni[stateProvince]);
      }
    }
  }, [stateProvince, ozara.subWorking]);

  useEffect(() => {
    serArrayCenters([]);

    //
    const getCenters = async () => {
      if (
        ozara.mainWorking !== "" ||
        stateProvince !== "" ||
        ozara.name !== "" ||
        ozara.subName !== ""
      ) {
        if (showCenters) {
          try {
            const res = await instance.get("form/center", {
              params: {
                provinceName: stateProvince,
                mainWork: ozara.mainWorking,
                subWork: ozara.subWorking,
                name:
                  ozara.subWorking === "mahaad" ? ozara.subName : ozara.name,
              },
            });

            if (res.data?.length > 0) {
              const filter = res.data?.map((item) => {
                return { label: item.name, value: item.id };
              });

              serArrayCenters(filter);
            }
          } catch (error) {
            console.log(error.message);
          }
        }
      }
    };
    getCenters();
  }, [
    stateProvince,
    ozara.mainWorking,
    ozara.subWorking,
    ozara.name,
    ozara.subName,
  ]);

  const workPlaceToCenterConverter = {
    higherEducation: "employmentCenterId",
    education: "mahanneSchoolId",
    youthAndSports: "publicCenterId",
    vocationalTraining: "publicCenterId",
    labor: "mainIncubationId", //other query from incubator table
  };

  const handleSubmit = async () => {
    if (showCenters) {
      const error = validationSubmit({
        ozara,
        needs,
        form,
        otherNeed,
        countTable,
        seatingbenches,
        centerName,
        checkHaveNeed,
        chooseCenter,
        checkedNotFoundCenter,
      });

      if (error !== "") {
        return alert(error);
      }

      try {
        setLoading(true);

        if (chooseCenter !== "" && ozara.mainWorking !== "labor") {
          form.values.center[workPlaceToCenterConverter[ozara.mainWorking]] =
            chooseCenter;
        }

        if (ozara.mainWorking === "labor" && arrayCenters.length === 0) {
          form.values.center.mainType = ozara.mainWorking;
        }

        if (ozara.mainWorking === "labor" && arrayCenters.length > 0) {
          form.values.center.mainType = ozara.mainWorking;
          form.values.center.mainIncubationId = arrayCenters[0].value;
          form.values.center.province = stateProvince;
          delete form.values.address;
        }

        if (ozara.mainWorking) delete form.values.center["undefined"];

        if (chooseCenter !== "") {
          delete form.values.address;
        }

        const res = await instance.post("form/center/", form.values);

        if (res.data.error === "alreadyExists") {
          setLoading(false);
          return alert("رقم الهاتف مستخدم بلفعل");
        }

        if (res.data.error) {
          setLoading(false);
          return alert("ادخالات خاطئة");
        }

        if (res.status === 200) {
          alert("تم تقديم استمارتك بنجاح");
          window.location.reload();
        }
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    }

    if (showIncubators) {
      if (
        form.values.trainer.birthdate === "" ||
        form.values.trainer.province === "" ||
        chooseMohIncubator === ""
      ) {
        return alert("جميع الحقول مطلوبة");
      }

      if (form.values.trainer.email === "") {
        delete form.values.trainer.email;
      }

      try {
        const res = await instance.post("form/center", {
          trainer: form.values.trainer,
          mainIncubationId:
            chooseIncubator !== "" ? chooseIncubator : undefined,
        });

        if (res.data.error) {
          return alert("خطأ ما");
        }

        if (res.status === 200) {
          alert("تم تقديم استمارتك بنجاح");
          window.location.reload();
        }
      } catch (error) {
        console.log(error.message);
      }
    }
  };

  const filterMudereaMohs = () => {
    if (stateProvince === "بغداد") {
      return muderreaMoh.slice(0, 6);
    }
    return muderreaMoh.filter((item) => item.value === stateProvince);
  };

  useEffect(() => {
    const getMohs = async () => {
      const res = await instance.get("provinceAndMuderrea");

      const format = res.data.map((item) => {
        return { label: item.name, value: item.id };
      });

      setArrayMohsIncubator(format);
    };

    getMohs();
  }, []);

  useEffect(() => {
    const getIncubators = async () => {
      const res = await instance.get("form/incubation", {
        params: {
          provinceId: chooseMohIncubator,
        },
      });

      const format = res.data.map((item) => {
        return { label: item.title, value: item.id };
      });
      setGetIncubators(format);
    };

    if (chooseMohIncubator) {
      getIncubators();
    }
  }, [chooseMohIncubator]);

  //
  return (
    <Paper withBorder shadow={"xl"} p={20} mt={30} radius="md">
      <div className="img">
        <img src={logo} />
      </div>
      <p className="note" style={{ fontSize: "15px", color: "#666" }}>
        ملاحظة : جميع الحقول التي تحتوي على{" "}
        <span style={{ color: "red", fontSize: "1rem" }}> * </span> هي حقول
        مطلوبة{" "}
      </p>

      <h2 className="titleInfo">المعلومات الشخصية</h2>
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <div className="wrapperDev">
          <TextInput
            size="md"
            withAsterisk
            label="الاسم الاول"
            {...form.getInputProps("trainer.firstName")}
          />
          <TextInput
            size="md"
            withAsterisk
            label="اسم الاب"
            {...form.getInputProps("trainer.fatherName")}
          />
          <TextInput
            size="md"
            withAsterisk
            label="اسم الجد"
            {...form.getInputProps("trainer.grandFatherName")}
          />
        </div>
        <Select
          size="md"
          withAsterisk
          data={arrayYears}
          label="سنة الميلاد"
          onChange={(e) => {
            form.values.trainer.birthdate = e;
          }}
        />
        <Select
          data={arrayMohs}
          size="md"
          withAsterisk
          label="المحافظة"
          onChange={(e) => {
            form.values.trainer.province = e;
          }}
        />
        <Select
          size="md"
          data={[
            { label: "دبلوم", value: "DIPLOM" },
            { label: "بكالوريوس", value: "BACHELOR" },
            { label: "دراسات عليا", value: "HIGHER" },
          ]}
          onChange={(e) => {
            form.values.trainer.degree = e;
          }}
          label="التحصيل الدراسي"
          placeholder="اختر"
          withAsterisk
        />
        <div className="wrapperDev">
          <TextInput
            size="md"
            label="البريد الالكتروني"
            {...form.getInputProps("trainer.email")}
          />

          <TextInput
            size="md"
            withAsterisk
            type={"number"}
            label="رقم الهاتف"
            {...form.getInputProps("trainer.phone")}
          />
        </div>

        <h2 className="titleInfo"> معلومات العمل وعنوان المركز التدريبي</h2>
        <div style={{ display: "flex", justifyContent: "center", gap: "1rem" }}>
          <Button
            onClick={() => {
              form.values.address.province = "";
              setShowCenters(true);
              setShowIncubators(null);
              setChooseMohIncubator("");
              setStateProvince("");
              setChooseIncubator("");
              setOzara({
                mainWorking: "",
                subWorking: "",
                name: "",
                subName: "",
              });
            }}
            color={"teal"}
            fullWidth
          >
            انشاء مركز
          </Button>

          <Button
            onClick={() => {
              form.values.address.province = "";
              setShowIncubators(true);
              setShowCenters(null);
              setChooseMohIncubator("");
              setStateProvince("");
              setChooseIncubator("");
              setOzara({
                mainWorking: "",
                subWorking: "",
                name: "",
                subName: "",
              });
            }}
            color={"teal"}
            fullWidth
          >
            انشاء حاضنة
          </Button>
        </div>

        {showCenters && (
          <Select
            size="md"
            data={arrayMohs}
            withAsterisk
            label="المحافظة"
            onChange={(e) => {
              form.values.address.province = e;
              setStateProvince(e);
              setOzara({
                mainWorking: "",
                subWorking: "",
                name: "",
                subName: "",
              });
            }}
          />
        )}

        {showIncubators && (
          <React.Fragment>
            <Select
              size="md"
              data={arrayMohsIncubator}
              withAsterisk
              label="المحافظة"
              onChange={setChooseMohIncubator}
            />

            {chooseMohIncubator && getIncubators.length === 0 ? (
              <Select
                size="md"
                data={[]}
                placeholder="لا توجد حاضنة"
                disabled
                withAsterisk
              />
            ) : chooseMohIncubator && getIncubators.length > 0 ? (
              <Select
                size="md"
                data={getIncubators}
                onChange={(e) => {
                  setChooseIncubator(e);
                }}
                label="اختر الحاضنة"
                withAsterisk
              />
            ) : (
              ""
            )}
          </React.Fragment>
        )}

        {showCenters && (
          <React.Fragment>
            <Select
              size="md"
              data={arrayOzara}
              onChange={(e) => {
                setOzara({
                  ...ozara,
                  mainWorking: e,
                  name: "",
                  subWorking: "",
                  subName: "",
                });
                setCheckedNotFoundCenter(false);
              }}
              label="الوزارات"
              placeholder="اختر"
              withAsterisk
            />

            {ozara.mainWorking === "higherEducation" && (
              <Select
                size="md"
                data={[
                  { label: "جامعة حكومية", value: "goveUniversty" },
                  { label: "جامعة اهلية", value: "privateUniversty" },
                  { label: "معهد تقني", value: "mahaad" },
                ]}
                onChange={(e) => {
                  setOzara({ ...ozara, subWorking: e, name: "", subName: "" });
                  setChooseCenter("");
                }}
                label={" الجامعة او المعهد"}
                placeholder="اختر"
                withAsterisk
              />
            )}

            {ozara.subWorking === "goveUniversty" ? (
              <Select
                size="md"
                data={dateUnversity}
                onChange={(e) => {
                  setOzara({ ...ozara, name: e, subName: "" });
                  setChooseCenter("");
                }}
                label={"اختر الجامعة الحكومية "}
                placeholder="اختر"
                value={ozara.name}
                withAsterisk
              />
            ) : ozara.subWorking === "privateUniversty" ? (
              <Select
                size="md"
                data={dateUnversity}
                onChange={(e) => {
                  setOzara({ ...ozara, name: e, subName: "" });
                  setChooseCenter("");
                }}
                label={"اختر الجامعة الاهلية"}
                value={ozara.name}
                placeholder="اختر"
                withAsterisk
              />
            ) : ozara.subWorking === "mahaad" ? (
              <Select
                size="md"
                data={namesMahed}
                onChange={(e) => {
                  setOzara({ ...ozara, name: e.label, subName: "" });
                  setIndexMahed(e.index);
                  setChooseCenter("");
                }}
                label={"اختر المعهد"}
                placeholder="اختر"
                withAsterisk
              />
            ) : (
              ""
            )}
            {ozara.subWorking === "mahaad" && indexMahed !== null ? (
              <Select
                size="md"
                data={maheds[indexMahed]}
                onChange={(e) => {
                  setOzara({ ...ozara, subName: e });
                }}
                label={"اختر اسم المعهد"}
                placeholder="اختر"
                withAsterisk
                value={ozara.subName}
              />
            ) : (
              ""
            )}
            {ozara.mainWorking === "higherEducation" &&
              checkedNotFoundCenter && (
                <TextInput
                  size="md"
                  withAsterisk
                  label="ادخل اسم المركز"
                  placeholder="ادخل اسم المركز .."
                  onChange={(e) => setCenterName(e.target.value)}
                />
              )}

            {ozara.mainWorking === "education" && (
              <Select
                data={filterMudereaMohs()}
                size="md"
                onChange={(e) =>
                  setOzara({ ...ozara, subWorking: e, name: "" })
                }
                placeholder="اختر المديرية"
                withAsterisk
              />
            )}
            {ozara.mainWorking === "education" && checkedNotFoundCenter && (
              <TextInput
                size="md"
                placeholder="ادخل اسم الاعدادية المهنية"
                withAsterisk
                value={ozara.name}
                onChange={(e) => setOzara({ ...ozara, name: e.target.value })}
              />
            )}
            {ozara.mainWorking === "youthAndSports" && (
              <Select
                data={[
                  { label: "منتدى الشباب", value: "youthForum" },
                  {
                    label: "منتدى الرعاية العملية",
                    value: "scientificCareForum",
                  },
                ]}
                size="md"
                onChange={(e) => {
                  setOzara({ ...ozara, subWorking: e, name: "" });
                  setChooseCenter("");
                }}
                placeholder="اختر المنتدى"
                withAsterisk
              />
            )}
            {ozara.subWorking === "youthForum" && checkedNotFoundCenter ? (
              <TextInput
                size="md"
                placeholder="ادخل اسم منتدى الشباب"
                withAsterisk
                onChange={(e) => {
                  setOzara({ ...ozara, name: e.target.value });
                }}
                value={ozara.name}
              />
            ) : ozara.subWorking === "scientificCareForum" &&
              checkedNotFoundCenter ? (
              <TextInput
                size="md"
                placeholder="ادخل اسم منتدى الرعاية العلمية"
                withAsterisk
                onChange={(e) => setOzara({ ...ozara, name: e.target.value })}
                value={ozara.name}
              />
            ) : null}
            {ozara.mainWorking === "vocationalTraining" && (
              <Select
                data={[
                  {
                    label: "مركز التدريب المهني",
                    value: "vocationalTrainingCentre",
                  },
                  {
                    label: "مركز التدريب الشعبي",
                    value: "peoplesTrainingCentre",
                  },
                ]}
                size="md"
                onChange={(e) => {
                  setOzara({ ...ozara, subWorking: e, name: "" });
                  setChooseCenter("");
                }}
                placeholder="اختر اسم المركز التدريبي"
                withAsterisk
              />
            )}
            {ozara.subWorking === "vocationalTrainingCentre" &&
            checkedNotFoundCenter ? (
              <TextInput
                size="md"
                placeholder="ادخل مركز التدريب المهني"
                withAsterisk
                onChange={(e) => setOzara({ ...ozara, name: e.target.value })}
                value={ozara.name}
              />
            ) : ozara.subWorking === "peoplesTrainingCentre" &&
              checkedNotFoundCenter ? (
              <TextInput
                size="md"
                placeholder="ادخل مركز التدريب الشعبي"
                withAsterisk
                onChange={(e) => setOzara({ ...ozara, name: e.target.value })}
                value={ozara.name}
              />
            ) : null}

            {ozara.mainWorking == "higherEducation" &&
              ozara.name !== "" &&
              checkedNotFoundCenter === false &&
              arrayCenters.length > 0 && (
                <>
                  <Select
                    data={arrayCenters}
                    size="md"
                    onChange={(e) => {
                      setChooseCenter(e);
                    }}
                    placeholder=".."
                    label="اختر مركز"
                  />
                </>
              )}

            {ozara.mainWorking !== "higherEducation" &&
              ozara.subWorking !== "" &&
              checkedNotFoundCenter === false &&
              arrayCenters.length > 0 && (
                <>
                  <Select
                    data={arrayCenters}
                    size="md"
                    onChange={(e) => {
                      setChooseCenter(e);
                    }}
                    placeholder=".."
                    label="اختر مركز"
                  />
                </>
              )}

            {ozara.subWorking.length > 0 && chooseCenter === "" && (
              <Checkbox
                onChange={(e) => {
                  setCheckedNotFoundCenter(!checkedNotFoundCenter);
                }}
                label="لم اجد مركزي هنا"
              />
            )}

            {chooseCenter !== "" && (
              <span
                onClick={() => {
                  setChooseCenter("");
                  serArrayCenters([]);
                  setStateProvince(stateProvince);
                }}
                style={{ color: "red", fontSize: "12px", cursor: "pointer" }}
              >
                مسح حقل المركز
              </span>
            )}
          </React.Fragment>
        )}

        {chooseCenter === "" && showCenters && (
          <React.Fragment>
            <span>عنوان المركز (القاعة التدريبية)</span>
            <div className="wrapperDev">
              <TextInput
                size="md"
                withAsterisk
                label="المدينة"
                {...form.getInputProps("address.city")}
              />

              <TextInput
                size="md"
                withAsterisk
                label="الحي"
                {...form.getInputProps("address.alhayee")}
              />
            </div>
            <div className="wrapperDev">
              <TextInput
                size="md"
                label="المحلة"
                {...form.getInputProps("address.mahlla")}
              />
              <TextInput
                size="md"
                label="الزقاق"
                {...form.getInputProps("address.zukak")}
              />
              <TextInput
                size="md"
                withAsterisk
                label="أقرب نقطة"
                {...form.getInputProps("address.endpoint")}
              />
            </div>
          </React.Fragment>
        )}

        {showCenters && (
          <React.Fragment>
            <h2 className="titleInfo">
              الاحتياجات الضرورية المتعلقة بالتدريب{" "}
            </h2>
            <Select
              data={[
                { label: "نعم", value: true },
                { label: "لا", value: false },
              ]}
              size="md"
              onChange={setCheckHaveNeed}
              placeholder=".."
              withAsterisk
              label="هل لديك قاعة جاهزة لتدريب"
            />
            {checkHaveNeed === false && (
              <div>
                <Checkbox
                  onChange={(e) => {
                    setNeeds({ ...needs, laptop: e.currentTarget.checked });
                  }}
                  label="لابتوب"
                />
                <Checkbox
                  onChange={(e) => {
                    setNeeds({ ...needs, datashow: e.currentTarget.checked });
                  }}
                  label="داتا شو"
                />
                <Checkbox
                  onChange={(e) =>
                    setNeeds({ ...needs, screen: e.currentTarget.checked })
                  }
                  label="شاشة عرض"
                />
                <Checkbox
                  onChange={(e) =>
                    setNeeds({ ...needs, smartboard: e.currentTarget.checked })
                  }
                  label="سبورة ذكية (متحركة)"
                />

                <Checkbox
                  onChange={(e) =>
                    setNeeds({ ...needs, printer: e.currentTarget.checked })
                  }
                  label="طابعة"
                />
                <Checkbox
                  onChange={(e) => {
                    setNeeds({ ...needs, table: e.currentTarget.checked });
                    setCountTable("");
                  }}
                  label="منضدة"
                />

                {needs?.table === true && (
                  <TextInput
                    size="sm"
                    type={"number"}
                    label="ادخل عدد المناضد"
                    placeholder="123"
                    onChange={(e) => {
                      setCountTable(e.target.value);
                    }}
                    style={{
                      marginBottom: "1rem",
                    }}
                  />
                )}

                <Checkbox
                  onChange={(e) => {
                    setNeeds({ ...needs, chir: e.currentTarget.checked });
                    setSeatingBenches("");
                  }}
                  label="مقاعد جلوس"
                />
                {needs?.chir === true && (
                  <TextInput
                    type={"number"}
                    size="sm"
                    label="ادخل عدد مقاعد الجلوس"
                    placeholder="123"
                    onChange={(e) => {
                      setSeatingBenches(e.target.value);
                    }}
                    style={{
                      marginBottom: "1rem",
                    }}
                    required={true}
                  />
                )}

                <Checkbox
                  onChange={(e) => {
                    setNeeds({ ...needs, others: e.currentTarget.checked });
                    setOtherNeed("");
                  }}
                  label="اخرى"
                />

                {needs?.others === true && (
                  <TextInput
                    size="sm"
                    onChange={(e) => setOtherNeed(e.target.value)}
                    label="ادخل احتياجك"
                    placeholder="....."
                    withAsterisk
                    required
                  />
                )}
              </div>
            )}
          </React.Fragment>
        )}

        <Button
          type="submit"
          loading={loading}
          fz={"md"}
          h={50}
          fullWidth
          mt="md"
          loaderPosition="right"
          className="button"
        >
          <span style={{ marginLeft: "10px" }}>ارسال الاستمارة</span>
        </Button>
      </form>
      <div
        className="bgImg"
        style={{
          backgroundImage: `url(${group})`,
        }}
      ></div>
    </Paper>
  );
}
